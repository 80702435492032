




































































































































































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {TrackListItem} from '@/types';
import UTable, {TableField} from '@/ui-lib/table/Table.vue';
import {MusicPlayerTrack, player} from '@/components/music-player/Player.vue';
import UCheckbox from '@/ui-lib/checkbox/Checkbox.vue';
import UButton from '@/ui-lib/button/Button.vue';
import {TrackService, PlaylistService, TagService} from '@/api';
import {PlaylistsSelectModalParams} from '@/components/modals/PlaylistsSelectModal.vue';
import {TrackTagsSelectModalParams} from "@/components/modals/TrackTagsSelectModal.vue";
import {SelectMultipleValue} from "@/ui-lib/select-field/SelectField.vue";
import {tag} from "@/store/modules/tag";

@Component({
  components: {
    UTable,
    UCheckbox,
    UButton,
  }
})
export default class TrackTableList extends Vue {
  @Prop({required: true, type: Array})
  private list!: TrackListItem[];

  @Prop({type: Boolean, default: true})
  private hasDownload!: boolean;

  @Prop({type: Boolean, default: false})
  private hasSelectNext!: boolean;

  @Prop({type: Boolean, default: false})
  private hasSelectDelete!: boolean;

  @Prop({type: Boolean, default: false})
  private hasSelectPlaylists!: boolean;

  @Prop({type: Boolean, default: false})
  private hasSelectCompilations!: boolean;

  @Prop({type: Boolean, default: false})
  private hasSelectTags!: boolean;

  @Prop({type: Array, default: () => ([])})
  private selected!: number[];

  @Prop({
    type: Array, default: (): TableField[] => [
      {
        name: 'select',
        label: '',
        width: '38px',
      },

      {
        name: 'play-pause',
        label: '',
        width: '40px',
      },

      {
        name: 'id',
        label: 'ID',
        width: '65px',
      },

      {
        name: 'title',
        label: 'Название',
      },

      {
        name: 'compilations',
        label: 'Подборки',
        width: '200px',
      },

      {
        name: 'playlists',
        label: 'Плейлисты',
        width: '200px',
      },

      {
        name: 'tags',
        label: 'Теги',
        width: '200px',
      },

      {
        name: 'likes',
        label: 'Лайки',
        width: '60px',
        align: 'right',
      },

      {
        name: 'dislikes',
        label: 'Дизлайки',
        width: '60px',
        align: 'right',
      },

      {
        name: 'listeners_per_month',
        label: 'Слуш/мес',
        width: '60px',
        align: 'right',
      },

      {
        name: 'skips_per_month',
        label: 'Скип/мес',
        width: '60px',
        align: 'right',
      },

      {
        name: 'controls',
        label: '',
        align: 'right',
        width: '50px',
      },
    ],
  })
  private fields!: TableField[];
  private getDownloadMP3URLIsLoading = false;

  private isLoading = false;

  private toggleSelectTrack(track: TrackListItem, val: boolean) {
    const i = this.selected.indexOf(track.id);

    if (val) {
      if (i === -1) {
        this.selected.push(track.id);
      }
    } else {
      if (i >= 0) {
        this.selected.splice(i, 1);
      }
    }
  }

  private togglePlay(track: TrackListItem) {
    const playerTrack: MusicPlayerTrack = {
      id: track.id,
      trackTitle: track.title,
      artistName: track.artist.name,
      src: track.url,
    };

    player.togglePlay(playerTrack);
  }

  private changeCompilations(track: TrackListItem) {
    this.$modal.show('track-change-compilation', {
      track: track,
    });
  }

  private changePlaylists(track: TrackListItem) {
    const ids = track.playlists.map((p) => p.id);

    const params: PlaylistsSelectModalParams = {
      selected: ids,
      onSelect: async () => {
        this.isLoading = true;

        try {
          const tr = await PlaylistService.trackSync(track.id, ids);

          track.playlists = tr.playlists;

          this.$notify({
            type: 'success',
            title: 'Трек обновлен',
          });

          this.$emit('playlists-changed', track);
        } catch (e) {
          this.$notify({
            type: 'error',
            title: 'Ошибка',
            text: e?.message || 'Неизвестная ошибка',
          });
        }

        this.isLoading = false;
      },
    }

    this.$modal.show('playlists-select', params);
  }

  private changeTags(track: TrackListItem) {
    const params: TrackTagsSelectModalParams = {
      selected: track.tags,
      onSelect: async (value: SelectMultipleValue) => {
        const tags: { name: string }[] = [];

        if (value) {
          if (Array.isArray(value)) {
            tags.push(...value.map((v) => ({name: v.label})));
          } else {
            tags.push({name: value.label});
          }
        }

        track.tags = await TagService.syncTrack(track.id, tags);
        await tag.context(this.$store).actions.loadList()

        this.$notify({
          type: 'success',
          title: 'Трек обновлен',
        });

        this.$emit('tags-changed', track);
      },
    }

    this.$modal.show('track-tags-select', params);
  }

  private isPlaying(track: TrackListItem): boolean {
    return player.isPlaying && player.playingTrack?.id === track.id;
  }

  private compilations(track: TrackListItem) {
    return track.compilations.filter((v, ind) => ind === track.compilations.findIndex((t) => t.id === v.id))
  }

  private selectAll() {
    this.list.forEach((track) => {
      const i = this.selected.indexOf(track.id);

      if (i === -1 && track.status === 1) {
        this.selected.push(track.id);
      }
    });
  }

  private unselectAll() {
    this.selected.splice(0);
  }

  private async downloadSelected() {
    this.getDownloadMP3URLIsLoading = true;

    try {
      const {url} = await TrackService.getMP3DownloadURL(this.selected);

      const win = window.open(url, '_blank');

      if (win) {
        win.focus();
      }
    } catch (e) {
      alert(`Error: ${e.message}`);
    }

    this.getDownloadMP3URLIsLoading = false;
  }

  private selectNext() {
    this.$emit('select-next');
  }

  private selectDelete() {
    this.$emit('select-delete');
  }

  private selectPlaylists() {
    this.$emit('select-playlists');
  }

  private selectCompilations() {
    this.$emit('select-compilations');
  }

  private selectTags() {
    this.$emit('select-tags');
  }
}
