var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"playlist-table-list"},[_c('u-table',{attrs:{"data":_vm.list,"fields":_vm.fields},scopedSlots:_vm._u([{key:"column_image",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"playlist-table-list_image"},[_c('router-link',{attrs:{"to":_vm.itemRoute(row)}},[_c('img',{attrs:{"src":row.image ? row.image : '/images/no_image_40px.png'}})])],1)]}},{key:"column_title",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"playlist-table-title"},[_c('router-link',{staticClass:"link",attrs:{"to":_vm.itemRoute(row)}},[_vm._v(" "+_vm._s(row.title)+" ")])],1)]}},{key:"column_hasVocals",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"playlist-table-list_hasVocals"},[(row.hasVocals === true)?_c('span',[_vm._v("Да")]):(row.hasVocals === false)?_c('span',[_vm._v("Нет")]):_c('span',[_vm._v("-")])])]}},{key:"column_musicPresetIDs",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"playlist-table-list_preset"},[_vm._v(" "+_vm._s(_vm.presetsName(row))+" ")])]}},{key:"column_tagIDs",fn:function(ref){
var row = ref.row;
return [(row.tags && row.tags.length)?_c('div',{staticClass:"playlist-table-list_tags"},_vm._l((row.tags),function(tag){return _c('router-link',{key:tag.id,staticClass:"playlist-table-list_tag",attrs:{"to":{name: 'tagItem', params: {id: tag.id.toString()}}}},[_vm._v(" "+_vm._s(tag.name)+" "),_c('span',[_vm._v(_vm._s(tag.count))])])}),1):_vm._e()]}},{key:"column_totalTracks",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"playlist-table-list_totalTracks"},[_c('router-link',{staticClass:"link",attrs:{"to":_vm.itemTracksRoute(row)}},[_vm._v(" "+_vm._s(row.totalTracks.toLocaleString())+" ")])],1)]}},{key:"column_totalDuration",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"playlist-table-list_totalDuration"},[_vm._v(" "+_vm._s(_vm.durationText(row))+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }