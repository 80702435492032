




















































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Tag, TrackListItem} from '@/types';
import {PlaylistService, TagService, TrackService} from "@/api";
import UBlock from "@/ui-lib/block/Block.vue";
import UButton from "@/ui-lib/button/Button.vue";
import TrackTableList from "@/components/track/TrackTableList.vue";
import {TableField} from '@/ui-lib/table/Table.vue';
import {TracksSelectModalParams} from "@/components/modals/TracksSelectModal.vue";
import {PlaylistsSelectModalParams} from "@/components/modals/PlaylistsSelectModal.vue";
import {Route} from "vue-router";

@Component<TagItemTracksView>({
  components: {
    TrackTableList,
    UBlock,
    UButton,
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const {list, total} = await TrackService.list({
        start: 0,
        limit: 99999,
        order_column: 'tgtr_id',
        order_dir: 'asc',
        tagID: parseInt(to.params.id, 10),
      });

      next((vm) => {
        vm.tracks = list;
        vm.total = total;
      });
    } catch (e) {
      next(e);
    }
  },

  async beforeRouteUpdate(to, from, next) {
    try {
      await this.updateList(to);
      next();
    } catch (e) {
      next(e);
    }
  },
})
export default class TagItemTracksView extends Vue {
  @Prop({required: true})
  private tag!: Tag;

  private total = 0;
  private tracks: TrackListItem[] = [];
  private isLoading = false;

  private selected: number[] = [];

  private get tableFields(): TableField[] {
    return [
      {
        name: 'select',
        label: '',
        width: '38px',
      },

      {
        name: 'play-pause',
        label: '',
        width: '40px',
      },

      {
        name: 'id',
        label: 'ID',
        width: '65px',
      },

      {
        name: 'title',
        label: 'Название',
      },

      {
        name: 'compilations',
        label: 'Подборки',
        width: '200px',
      },

      {
        name: 'playlists',
        label: 'Плейлисты',
        width: '200px',
      },

      {
        name: 'tags',
        label: 'Теги',
        width: '200px',
      },

      {
        name: 'likes',
        label: 'Лайки',
        width: '60px',
        align: 'right',
      },

      {
        name: 'dislikes',
        label: 'Дизлайки',
        width: '60px',
        align: 'right',
      },

      {
        name: 'listeners_per_month',
        label: 'Слуш/мес',
        width: '60px',
        align: 'right',
      },

      {
        name: 'skips_per_month',
        label: 'Скип/мес',
        width: '60px',
        align: 'right',
      },

      {
        name: 'controls',
        label: '',
        align: 'right',
        width: '50px',
      },
    ];
  }

  private showSelect() {
    const ids: number[] = [];

    const params: TracksSelectModalParams = {
      selected: ids,
      excludeTagID: this.tag.id,
      onSelect: async () => {
        this.isLoading = true;

        try {
          await TagService.attachTracks(ids, [{name: this.tag.name}]);
          await this.updateList(this.$route);

          this.$notify({
            type: 'success',
            text: `Тег добавлен в треки (${ids.length})`,
          });
        } catch (e) {
          this.$notify({
            type: 'error',
            title: 'Ошибка',
            text: e?.message || 'Неизвестная ошибка',
          });
        }

        this.isLoading = false;
      },
    };

    this.$modal.show('tracks-select', params);
  }

  public async updateList(to: Route) {
    this.isLoading = true;

    try {
      const {list, total} = await TrackService.list({
        start: 0,
        limit: 99999,
        order_column: 'tgtr_id',
        order_dir: 'asc',
        tagID: parseInt(to.params.id, 10),
      });

      this.tracks = list;
      this.total = total;

      this.tag.trackCount = total;
    } catch (e) {
      this.$notify({
        type: 'error',
        title: 'Ошибка',
        text: e?.message || 'Неизвестная ошибка',
      });
    }

    this.isLoading = false;
  }

  private detachItem(track: TrackListItem) {
    if (!confirm(`Удалить тег «‎${this.tag.name}»‎ из трека «‎${track.title}»‎?`)) {
      return;
    }

    this.detachIDs([track.id]);
  }

  private async detachIDs(ids: number[]) {
    if (ids.length === 0) {
      this.$notify({type: 'info', title: 'Нет изменений'});
      return;
    }

    this.isLoading = true;

    try {
      await TagService.detachTracks(ids, [this.tag.id]);
      await this.updateList(this.$route);

      ids.forEach((id) => {
        const i = this.selected.indexOf(id);
        if (i !== -1) {
          this.selected.splice(i, 1);
        }
      });

      this.$notify({
        type: 'success',
        text: 'Теги удалены',
      });
    } catch (e) {
      this.$notify({
        type: 'error',
        title: 'Ошибка',
        text: e?.message || 'Неизвестная ошибка',
      });
    }

    this.isLoading = false;
  }

  private selectDelete() {
    if (!confirm(`Удалить тег «‎${this.tag.name}» у треков (${this.selected.length})‎?`)) {
      return;
    }

    this.detachIDs([...this.selected]);
  }

  private tagsChanged() {
    this.updateList(this.$route);
  }

  private selectPlaylists() {
    const playlistIDs: number[] = [];

    const params: PlaylistsSelectModalParams = {
      selected: playlistIDs,
      onSelect: async () => {
        if (playlistIDs.length === 0) {
          this.$notify({
            type: 'info',
            title: 'Нет изменений',
          });
          return
        }

        this.isLoading = true

        try {
          await PlaylistService.addTracks(playlistIDs, this.selected)

          await this.updateList(this.$route);

          this.$notify({
            type: 'success',
            title: 'Треки обновлены',
          });
        } catch (e) {
          this.$notify({
            type: 'error',
            title: 'Ошибка',
            text: e?.message || 'Неизвестная ошибка',
          });
        }

        this.isLoading = false
      },
    }

    this.$modal.show('playlists-select', params);
  }
}
