/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'filter-variant': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 13h12v-2H6v2zM3 6v2h18V6H3zm7 12h4v-2h-4v2z" _fill="#000"/>'
  }
})
