














































import {Component, Prop, Watch, Vue} from 'vue-property-decorator';
import {Tag, TagForm, createTagForm} from "@/types";
import UButton from '@/ui-lib/button/Button.vue';
import UTextField from "@/ui-lib/text-field/TextField.vue";
import UAlert from "@/ui-lib/alert/Alert.vue";
import USelectField from "@/ui-lib/select-field/SelectField.vue";
import {TagService} from '@/api';
import UCheckbox from "@/ui-lib/checkbox/Checkbox.vue";

@Component({
  components: {
    UCheckbox,
    UAlert,
    UButton,
    UTextField,
    USelectField,
  }
})
export default class TagFormView extends Vue {
  @Prop({required: false})
  private tag!: Tag;

  private form: TagForm = createTagForm();
  private isLoading = false;
  private errorMessage = '';
  private fails: Record<string, string[]> = {};

  @Watch('tag', {immediate: true})
  private tagChanged(val?: Tag) {
    this.form = createTagForm(val);
    this.errorMessage = '';
    this.fails = {};
  }

  private async onSubmit() {
    this.isLoading = true;

    try {
      const pl = await TagService.upsert(this.form);

      this.errorMessage = '';
      this.fails = {};

      this.$notify({
        type: 'success',
        text: `Тег ${this.form.id ? 'обновлен' : 'добавлен'}`,
      });

      this.$emit('saved', pl);
    } catch (e) {
      this.errorMessage = e?.message || 'Ошибка';
      this.fails = e?.validationFails || {};
    }

    this.isLoading = false;
  }
}
