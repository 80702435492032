import Vue from 'vue'
import VueRouter from 'vue-router'
import {routes} from './routes';
import NProgress from 'nprogress';

Vue.use(VueRouter);

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  async scrollBehavior(to, from, savedPosition) {
    return savedPosition || {x: 0, y: 0};
  },
});

export const state = new Vue({
  data() {
    return {
      isProgress: false,
    };
  },
});


router.beforeEach((to, from, next) => {
  state.$data.isProgress = true;
  NProgress.start();

  next();
});

router.afterEach(() => {
  NProgress.done();
  state.$data.isProgress = false;
});

router.onError(async (error: Error) => {
  alert(error);
  NProgress.done();
  state.$data.isProgress = false;
});
