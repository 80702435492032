























































































































































































import {Component, Prop, Watch, Vue} from 'vue-property-decorator';
import {Playlist, PlaylistForm, createPlaylistForm} from "@/types";
import {preset} from '@/store/modules/preset';
import UButton from '@/ui-lib/button/Button.vue';
import UTextField from "@/ui-lib/text-field/TextField.vue";
import UAlert from "@/ui-lib/alert/Alert.vue";
import USelectField, {SelectOptions, SelectValue} from "@/ui-lib/select-field/SelectField.vue";
import {PlaylistService} from '@/api';
import UCheckbox from "@/ui-lib/checkbox/Checkbox.vue";

@Component({
  components: {
    UCheckbox,
    UAlert,
    UButton,
    UTextField,
    USelectField,
  }
})
export default class PlaylistFormView extends Vue {
  @Prop({required: false})
  private playlist!: Playlist;

  private form: PlaylistForm = createPlaylistForm();
  private isLoading = false;
  private imagePreview = '';
  private errorMessage = '';
  private fails: Record<string, string[]> = {};

  @Watch('playlist', {immediate: true})
  private playlistChanged(val?: Playlist) {
    this.form = createPlaylistForm(val);
    this.imagePreview = val?.image || '';
    this.errorMessage = '';
    this.fails = {};
  }

  private async onSubmit() {
    this.isLoading = true;

    try {
      const pl = await PlaylistService.upsert(this.form);

      this.errorMessage = '';
      this.fails = {};

      this.$notify({
        type: 'success',
        text: `Плейлист ${this.form.id ? 'обновлен' : 'добавлен'}`,
      });

      this.$emit('saved', pl);
    } catch (e) {
      this.errorMessage = e?.message || 'Ошибка';
      this.fails = e?.validationFails || {};
    }

    this.isLoading = false;
  }

  private get presets() {
    return preset.context(this.$store).state.list;
  }

  private get presetsCheckboxes(): { id: number; label: string }[] {
    return this.presets.map((g) => {
      let label = g.name;

      if (label.startsWith('Музыка для ')) {
        label = g.name.replace('Музыка для ', '')
        label = label.charAt(0).toUpperCase() + label.slice(1)
      }

      return {
        id: g.id,
        label: label,
      }
    });
  }

  private getPreset(id: number): boolean {
    return this.form.musicPresetIDs.includes(id)
  }

  private setPreset(id: number, val: boolean): void {
    const i = this.form.musicPresetIDs.indexOf(id)

    if (val) {
      if (i === -1) {
        this.form.musicPresetIDs.push(id)
      }
    } else {
      if (i !== -1) {
        this.form.musicPresetIDs.splice(i, 1)
      }
    }
  }

  // private get preset(): SelectValue {
  //   return this.presetOptions.find((o) => o.value === this.form.musicPresetID) || null;
  // }
  //
  // private set preset(val: SelectValue) {
  //   this.form.musicPresetID = val ? parseInt(val.value.toString(), 10) : 0;
  // }

  private picFileChangeHandler(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files?.length) {
      const file = input.files[0];
      this.form.image = file;
      this.imagePreview = URL.createObjectURL(file);
    } else {
      this.form.image = null;
      this.imagePreview = '';
    }
  }

  private get dynamicsAcousticsOptions(): SelectOptions {
    return ' '.repeat(4).split('').map((v, i) => ({
      label: `${i + 1}`,
      value: i + 1,
    }));
  }

  private get dynamics(): SelectValue {
    return this.dynamicsAcousticsOptions.find((o) => o.value.toString() === this.form.dynamics?.toString()) || null;
  }

  private set dynamics(val: SelectValue) {
    this.form.dynamics = val ? parseInt(val.value.toString(), 10) : 0;
  }

  private get acoustics(): SelectValue {
    return this.dynamicsAcousticsOptions.find((o) => o.value.toString() === this.form.acoustics?.toString()) || null;
  }

  private set acoustics(val: SelectValue) {
    this.form.acoustics = val ? parseInt(val.value.toString(), 10) : 0;
  }
}
