




























import {Component, Prop, Vue} from 'vue-property-decorator';
import {Tag} from '@/types';
import UBlock from '@/ui-lib/block/Block.vue';
import TagFormView from '@/components/tag/TagForm.vue';

@Component<TagItemInfoView>({
  components: {
    UBlock,
    TagFormView,
  },
})
export default class TagItemInfoView extends Vue {
  @Prop({required: true})
  private tag!: Tag;
}
