import Vue from 'vue'
import * as Vuex from 'vuex'
import {createStore, Module} from 'vuex-smart-module'
import {auth} from './modules/auth';
import {genre} from './modules/genre';
import {compilation} from './modules/compilation';
import {preset} from './modules/preset';
import {tag} from './modules/tag';

Vue.use(Vuex);

const root = new Module({
  modules: {
    auth,
    genre,
    compilation,
    preset,
    tag,
  },
});

export const store = createStore(root);
