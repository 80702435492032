


















































import {Component, Vue} from 'vue-property-decorator';
import {Playlist} from '@/types';
import {PlaylistService} from '@/api';
import UButton from '@/ui-lib/button/Button.vue';
import UBlock from '@/ui-lib/block/Block.vue';
import UPagination from '@/ui-lib/pagination/Pagination.vue';
import PlaylistTableList from '@/components/playlist/PlaylistTableList.vue';
import {PlaylistFormModalParams} from '@/components/modals/PlaylistFormModal.vue';
import {Route} from "vue-router";

const limit = 30;

@Component<PlaylistListView>({
  components: {
    UPagination,
    UBlock,
    UButton,
    PlaylistTableList,
  },

  metaInfo() {
    return {
      title: 'Плейлисты',
    };
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const params = PlaylistService.prepareListParams(to, limit);
      const listRes = await PlaylistService.list(params);

      next((vm) => {
        vm.list = listRes.list;
        vm.total = listRes.total;
      });
    } catch (e) {
      next(e);
    }
  },

  async beforeRouteUpdate(to, from, next) {
    try {
      await this.updateList(to);
      next();
    } catch (e) {
      next(e);
    }
  },
})
export default class PlaylistListView extends Vue {
  private showCreate() {
    const params: PlaylistFormModalParams = {
      onSaved: (pl: Playlist) => {
        this.$router.push({
          name: 'playlistItem',
          params: {
            id: pl.id.toString(),
          },
        });
      },
    };

    this.$modal.show('playlist-form', params);
  }

  private total = 0;
  private list: Playlist[] = [];

  private get params() {
    return PlaylistService.prepareListParams(this.$route, limit);
  }

  private get limit() {
    return this.params.limit;
  }

  private get start() {
    return this.params.start;
  }

  private changeStart(newStart: number) {
    this.$router.push({
      query: {
        ...this.$route.query,
        start: newStart.toString(),
      },
    });
  }

  private async updateList(route: Route) {
    const params = PlaylistService.prepareListParams(route, limit);
    const {list, total} = await PlaylistService.list(params);

    this.list = list;
    this.total = total;
  }
}
