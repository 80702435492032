import {Getters, Mutations, Actions, Module} from 'vuex-smart-module';
import {Tag} from '@/types';
import {OrderDir, TagOrderColumn, TagService} from '@/api';

class TagState {
  list: Tag[] = [];
}

class TagMutations extends Mutations<TagState> {
  setList(list: Tag[]) {
    this.state.list = list;
  }
}

class TagGetters extends Getters<TagState> {
}

class TagActions extends Actions<TagState, TagGetters, TagMutations, TagActions> {
  async loadList() {
    const listRes = await TagService.list({
      limit: 99999,
      order_column: TagOrderColumn.id,
      order_dir: OrderDir.asc,
    });
    this.mutations.setList(listRes.list);
  }
}

export const tag = new Module({
  state: TagState,
  getters: TagGetters,
  mutations: TagMutations,
  actions: TagActions,
});
