export interface Tag {
  id: number;
  name: string;
  trackCount: number;
  search_aliases?: string[] | null;
}

export const createTag = (): Tag => ({
  id: 0,
  name: '',
  trackCount: 0,
})

export interface TagForm {
  id?: number;
  search_aliases: string[];
}

export const createTagForm = (tg?: Tag): TagForm => ({
  id: tg ? tg.id : undefined,
  search_aliases: tg?.search_aliases || [],
});
