





























































































































































































































import {Component, Prop, Watch, Vue} from 'vue-property-decorator';
import {Route} from "vue-router";
import {state as stateRouter} from '@/router';
import UCheckbox from '@/ui-lib/checkbox/Checkbox.vue';
import UTextField from '@/ui-lib/text-field/TextField.vue';

export interface TableFieldFilterItem {
  label: string;
  value: string;
}

export interface TableField {
  name: string;
  label: string;
  width?: string;
  align?: 'center' | 'left' | 'right';
  sortable?: boolean;
  filters?: TableFieldFilterItem[];
  filterWidth?: string;
  lazyFilterUpdated?: (v: string[]) => void;
}

type RouteQuery = Record<string, string | undefined>;

@Component({
  components: {
    UCheckbox,
    UTextField,
  }
})
export default class UTable extends Vue {
  @Prop({required: true, type: Array})
  // eslint-disable-next-line
  private readonly data!: Record<string, any>[];

  @Prop({required: true, type: Array})
  private readonly fields!: TableField[];

  @Prop({required: false, type: Boolean})
  private readonly sticky!: boolean;

  private localSortOrder: 'asc' | 'desc' = 'desc';
  private localSortField = '';

  private filterValues: { [fieldName: string]: string[] } = {};

  private filterTexts: Record<string, string> = {};

  private filteredFilters(f: TableField): TableFieldFilterItem[] {
    const fs = f.filters || [];

    return fs.filter((fd) => {
      if (f.name in this.filterValues && this.filterValues[f.name].includes(fd.value)) {
        return true;
      }

      if (this.filterTexts[f.name]) {
        return fd.label.toLowerCase().includes(this.filterTexts[f.name].toLowerCase());
      }

      return true;
    });
  }

  private get tableFields() {
    return this.fields;
  }

  private get tableData() {
    return this.data;
  }

  private getFieldTitle(field: TableField) {
    return field.label;
  }

  private getFieldName(field: TableField) {
    return field.name;
  }

  // eslint-disable-next-line
  private getItemFieldData(item: any, field: TableField) {
    return item[this.getFieldName(field)];
  }

  private getThClassNames(field: TableField) {
    const res = ['u-table_th', {
      'sortable': this.getFieldSortable(field),
      'sortable-active': this.getFieldSortableActive(field),
      'has-filter': this.getFieldHasFilter(field),
      'filtered': this.getFieldFiltered(field),
    }];

    if ('align' in field) {
      res.push('u-table_th__' + field.align);
    }

    return res;
  }

  private getThStyles(field: TableField) {
    const res: Record<string, string> = {};

    if (field.width) {
      res.width = field.width;
    }

    return res;
  }

  private getTdClassNames(field: TableField) {
    const res = ['u-table_td'];

    if ('align' in field) {
      res.push('u-table_td__' + field.align);
    }

    return res;
  }

  private getFieldSortable(field: TableField): boolean {
    return !!field.sortable;
  }

  private orderBy(field: TableField) {
    if (!this.getFieldSortable(field)) {
      return;
    }

    this.singleColumnSort(field);
  }

  private routePush(query: RouteQuery) {
    this.$router.push({
      name: this.$route.name || '',
      params: this.$route.params,
      query: {
        ...this.$route.query,
        ...query,
      },
    });
  }


  @Watch('$route', {immediate: true})
  private routeChanged(route: Route) {
    const {sortField, sortOrder} = route.query;

    this.localSortField = 'id';
    this.localSortOrder = 'desc';

    if (sortField) {
      this.localSortField = sortField.toString();
    }

    if (sortOrder) {
      const sortOrderStr = sortOrder.toString();

      if (sortOrderStr === 'asc' || sortOrderStr === 'desc') {
        this.localSortOrder = sortOrderStr;
      }
    }

    this.fields.forEach((f) => {
      if (!f.filters) {
        return;
      }

      if (!(f.name in this.filterValues)) {
        this.$set(this.filterValues, f.name, []);
      }

      if (route.query[f.name]) {
        const nv = route.query[f.name].toString();
        const nva = nv.split(',').filter((v) => f.filters && f.filters.find((fl) => fl.value === v));
        this.filterValues[f.name] = nva;
      } else {
        this.filterValues[f.name] = [];
      }
    });
  }

  private getInverseSorterOrder(sortOrder: string) {
    return sortOrder === 'asc' ? 'desc' : 'asc';
  }

  private singleColumnSort(field: TableField) {
    const oldSortField = this.localSortField;
    const oldSortOrder = this.localSortOrder;

    const newSortField = this.getFieldName(field);
    const newSortOrder = oldSortField === newSortField
            ? this.getInverseSorterOrder(oldSortOrder) : 'desc';

    this.routePush({
      sortField: newSortField,
      sortOrder: newSortOrder,
    });
  }


  private getIconSortDir(field: TableField) {
    if (this.getFieldSortableActive(field)) {
      return this.localSortOrder === 'asc' ? 'up' : 'down';
    }

    return 'up';
  }

  private getFieldSortableActive(field: TableField): boolean {
    return this.getFieldName(field) === this.localSortField;
  }

  private thClickHandler(field: TableField) {
    if (this.getFieldSortable(field) && this.getFieldHasFilter(field)) {
      //
    } else if (this.getFieldSortable(field)) {
      this.orderBy(field);
    }
  }

  private getFieldHasFilter(field: TableField): boolean {
    return !!field.filters;
  }

  private getFieldFiltered(field: TableField): boolean {
    if (!field.filters) {
      return false;
    }

    return this.filterValues[field.name] && this.filterValues[field.name].length > 0;
  }

  private getFieldFilterWidth(field: TableField): string {
    return field.filterWidth || '';
  }

  private inputFilter(field: TableField, filter: TableFieldFilterItem, val: boolean) {
    const values = this.filterValues[field.name] || [];
    const cValues = [...values];

    const i = cValues.indexOf(filter.value);

    if (val && i === -1) {
      cValues.push(filter.value);
    } else if (!val && i !== -1) {
      cValues.splice(i, 1);
    }

    const query: RouteQuery = {
      [field.name]: cValues.length ? cValues.join(',') : undefined,
    };

    if (this.$route.query.start) {
      query.start = '0';
    }

    this.routePush(query);
  }

  private filterValue(field: TableField, filter: TableFieldFilterItem) {
    if (this.filterValues[field.name]) {
      return this.filterValues[field.name].includes(filter.value);
    }

    return false;
  }

  private get isLoading() {
    return stateRouter.isProgress;
  }
}
