import {RouteConfig} from 'vue-router';
import BaseLayout from '@/layouts/BaseLayout.vue';
import RouterView from '@/layouts/RouterView.vue';
import {store} from '@/store';
import {auth} from '@/store/modules/auth';
import {genre} from '@/store/modules/genre';
import {compilation} from '@/store/modules/compilation';
import {preset} from '@/store/modules/preset';
import {tag} from '@/store/modules/tag';

const authCtx = auth.context(store);

export const routes: RouteConfig[] = [
  {
    path: '/',
    component: BaseLayout,

    beforeEnter: async (to, from, next) => {
      try {
        await authCtx.actions.loadAuth();

        next();
      } catch (e) {
        next(e);
      }
    },

    children: [
      {
        name: 'auth',
        path: '/auth',
        component: () => import('@/views/AuthView.vue'),
        beforeEnter: async (to, from, next) => {
          if (authCtx.getters.isAuth) {
            next({name: 'home'});
          } else {
            next();
          }
        },
      },

      {
        path: '/',
        component: () => import('@/layouts/CabinetLayout.vue'),
        beforeEnter: async (to, from, next) => {
          if (authCtx.getters.isAuth) {
            try {
              await Promise.all([
                genre.context(store).actions.loadList(),
                compilation.context(store).actions.loadList(),
                preset.context(store).actions.loadList(),
                tag.context(store).actions.loadList(),
              ]);

              next();
            } catch (e) {
              next(e);
            }
          } else {
            next({
              name: 'auth',
              query: {
                redirect: window.location.pathname + window.location.search,
              },
            });
          }
        },
        children: [
          {
            path: 'logout',
            name: 'logout',
            beforeEnter: async (to, from, next) => {
              try {
                await authCtx.actions.logout();
                next({name: 'auth'});
              } catch (e) {
                next(e);
              }
            },
          },

          {
            path: '',
            name: 'home',
            redirect: {
              name: 'tracksDistribution',
            },
          },

          {
            path: 'tracks-distribution',
            name: 'tracksDistribution',
            component: () => import('@/views/TracksDistributeView.vue'),
          },

          {
            path: 'compilation',
            name: 'compilation',
            redirect: {
              name: 'compilationList',
            },
            component: RouterView,
            children: [
              {
                path: 'list',
                name: 'compilationList',
                component: () => import('@/views/compilation/CompilationListView.vue'),
              },

              {
                path: ':id',
                name: 'compilationItem',
                component: () => import('@/views/compilation/CompilationView.vue'),
                redirect: {name: 'compilationItemInfo'},
                children: [
                  {
                    path: 'info',
                    name: 'compilationItemInfo',
                    component: () => import('@/views/compilation/CompilationInfoView.vue'),
                  },

                  {
                    path: 'tracks',
                    name: 'compilationItemTracks',
                    component: () => import('@/views/compilation/CompilationTracksView.vue'),
                  },
                ],
              },
            ],
          },

          {
            path: 'track',
            name: 'track',
            redirect: {
              name: 'trackList',
            },
            component: RouterView,
            children: [
              {
                path: 'list',
                name: 'trackList',
                component: () => import('@/views/track/TrackListView.vue'),
              },
            ],
          },

          {
            path: 'preset',
            name: 'preset',
            redirect: {
              name: 'presetList',
            },
            component: RouterView,
            children: [
              {
                path: 'list',
                name: 'presetList',
                component: () => import('@/views/preset/PresetListView.vue'),
              },

              {
                path: ':id',
                name: 'presetItem',
                component: () => import('@/views/preset/PresetItemView.vue'),
                redirect: {
                  name: 'presetItemCombinations',
                },
                children: [
                  {
                    path: 'combinations',
                    name: 'presetItemCombinations',
                    component: () => import('@/views/preset/item-sections/PresetItemCombinationsView.vue'),
                  },
                ],
              }
            ],
          },

          {
            path: 'combination',
            name: 'combination',
            component: RouterView,
            children: [
              {
                path: ':id',
                name: 'combinationItem',
                component: () => import('@/views/combination/CombinationItemView.vue'),
                redirect: {
                  name: 'combinationItemCompilations',
                },
                children: [
                  {
                    path: 'compilations',
                    name: 'combinationItemCompilations',
                    component: RouterView,
                    redirect: {
                      name: 'combinationItemCompilationsList',
                    },
                    children: [
                      {
                        path: 'list',
                        name: 'combinationItemCompilationsList',
                        component: () => import('@/views/combination/item-sections/CombinationCompilationsView.vue'),
                      },

                      {
                        path: 'edit',
                        name: 'combinationItemCompilationsEdit',
                        component: () => import('@/views/combination/item-sections/CombinationCompilationsEditView.vue'),
                      },
                    ],
                  },

                  {
                    path: 'tracks',
                    name: 'combinationItemTracks',
                    component: () => import('@/views/combination/item-sections/CombinationTracksView.vue'),
                  },
                ],
              }
            ],
          },

          {
            path: 'playlist',
            name: 'playlist',
            redirect: {
              name: 'playlistList',
            },
            component: RouterView,
            children: [
              {
                path: 'list',
                name: 'playlistList',
                component: () => import('@/views/playlist/PlaylistListView.vue'),
              },
              {
                path: ':id',
                name: 'playlistItem',
                component: () => import('@/views/playlist/PlaylistItemView.vue'),
                redirect: {
                  name: 'playlistItemInfo',
                },
                children: [
                  {
                    path: 'info',
                    name: 'playlistItemInfo',
                    component: () => import('@/views/playlist/PlaylistItemInfoView.vue'),
                  },

                  {
                    path: 'tracks',
                    name: 'playlistItemTracks',
                    component: () => import('@/views/playlist/PlaylistItemTracksView.vue'),
                  },
                ],
              }
            ],
          },

          {
            path: 'upload-album',
            name: 'uploadAlbum',
            component: () => import('@/views/upload/UploadAlbumView.vue'),
          },

          {
            path: 'tag',
            name: 'tag',
            redirect: {
              name: 'tagList',
            },
            component: RouterView,
            children: [
              {
                path: 'list',
                name: 'tagList',
                component: () => import('@/views/tag/TagListView.vue'),
              },
              {
                path: ':id',
                name: 'tagItem',
                component: () => import('@/views/tag/TagItemView.vue'),
                redirect: {
                  name: 'tagItemInfo',
                },
                children: [
                  {
                    path: 'info',
                    name: 'tagItemInfo',
                    component: () => import('@/views/tag/TagItemInfoView.vue'),
                  },
                  {
                    path: 'tracks',
                    name: 'tagItemTracks',
                    component: () => import('@/views/tag/TagItemTracksView.vue'),
                  },
                ],
              }
            ],
          },
        ],
      },
    ],
  },
];
