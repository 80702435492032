export interface PlaylistTag {
  id: number;
  name: string;
  count: number;
}

export interface Playlist {
  id: number;
  createdAt: string;
  updatedAt: string;
  pos: number;
  musicPresetIDs: number[];
  title: string;
  description?: string | null;
  image?: string | null;
  totalTracks: number;
  totalDuration: number;
  totalUses: number;
  dynamics: number | null;
  acoustics: number | null;
  tags?: PlaylistTag[] | null;
  search_aliases?: string[] | null;
  hasVocals?: boolean | null;
}

export const createPlaylist = (): Playlist => ({
  id: 0,
  createdAt: '',
  updatedAt: '',
  pos: 0,
  musicPresetIDs: [],
  title: '',
  totalTracks: 0,
  totalDuration: 0,
  totalUses: 0,
  dynamics: null,
  acoustics: null,
})

export interface PlaylistForm {
  id?: number;
  musicPresetIDs: number[];
  title: string;
  description: string;
  image: File | null;
  dynamics: number | null;
  acoustics: number | null;
  search_aliases: string[];
  hasVocals: 'null' | 'false' | 'true';
}

export const createPlaylistForm = (pl?: Playlist): PlaylistForm => ({
  id: pl ? pl.id : undefined,
  musicPresetIDs: pl?.musicPresetIDs ? [...pl?.musicPresetIDs] : [],
  title: pl?.title || '',
  description: pl?.description || '',
  image: null,
  dynamics: pl?.dynamics || null,
  acoustics: pl?.acoustics || null,
  search_aliases: pl?.search_aliases || [],
  hasVocals: ((): 'null' | 'false' | 'true' => {
    if (!pl) {
      return 'null'
    }

    if (pl.hasVocals === true) {
      return 'true'
    }

    if (pl.hasVocals === false) {
      return 'false'
    }

    return 'null'
  })(),
});
