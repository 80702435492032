/* eslint-disable */
require('./add')
require('./arrow-down-bold')
require('./arrow-down')
require('./arrow-right')
require('./arrow-up')
require('./close')
require('./delete')
require('./dots-horizontal')
require('./filter-variant')
require('./headphones')
require('./list-alt')
require('./logout')
require('./music-note')
require('./music-note2')
require('./pause')
require('./pencil')
require('./play')
require('./playlist-music-outline')
require('./sell')
require('./skip_next')
require('./thumb_down_fill')
require('./thumb_down')
require('./thumb_up_fill')
require('./thumb_up')
require('./tune')
require('./upload')
